<template>
	<main>
		<Header v-if="header" />
		<router-view />
	</main>
</template>

<script>
import Header from '@ebradi/componets/Header.vue';

import '@ebradi/assets/scss/base.scss'

export default {
	name: 'LayoutDefaultEBRADI',
	components: {
		Header
	},
	data() {
		return {
			header: true
		}
	},
	mounted() {
		if ('header' in this.$route.meta) {
			this.header = this.$route.meta.header
		}
	}
}
</script>